<template>
	<el-dialog title="资料上传" :visible.sync="dialogVisible" width="1112px" style="margin-top: 1vh">
	 
		<div class="info">
			
			
			<div id="infos">
				
				<p>教材名称：{{info.s_jname}}</p>  
				<p>第几版本：{{info.s_banben}} </p>  
				<p>封面标志性说明，适用人员，适用范围： {{info.s_shiyongren}}</p>  
				<br/>
				<p>题目、章节名称：{{info.s_timu}} </p>  
				<p>主编:{{info.s_zhubian}} </p>  
				<p>副主编: {{info.s_fuzubain}}</p>  
				<p>出版发行(社): {{info.s_chubanshe}}</p>  
				<br/> 
				<p>出版日期:{{info.s_chubantime}}</p>  
	 
				<p>图片制作上传者姓名: {{info.s_imgname}}</p>  
				<p>图片制作上传者所属单位（首选）或曾履职单位，学习、毕业学校:{{info.s_danwei}}</p>  
				
				 <p> 预期价格：{{info.price}}</p>  
			</div> 
			
			
			<hr/>

			<div>资料标题：{{info.title}}
				 
			 
				 
			</div>
			
			
			<div> 简介内容(限100字)：{{info.simple}}
				 
			</div> 

			<div> 资料内容(限2000字)：{{info.content}}
				 
			</div>
			
			

			<div> 上传视频：


				<div class="album albumvideo">
					<div>
						 
						<div class="pic_img">
						  <video width="320" height="240" controls :src="info.video_s"></video>
						</div>
					</div>
					 
				</div> 


			</div>



		</div>
 
	</el-dialog>


</template>

<script>
	import axios from "axios";
 
	export default {
		name: "PayStatusShow",
	 
		data() {
			return {
				info:[],
				s_jname:'',
				s_banben:'',
				s_shiyongren:'',
				s_timu:'',
				s_zhubian:'',
				s_fuzubain:'',
				s_chubanshe:'',
				s_chubantime:'',
				s_imgname:'',
				s_danwei:'',
				type:1,
				//---------------
				videoFlag: false,
				//是否显示进度条
				videoUploadPercent: "",
				//进度条的进度，
				isShowUploadVideo: false,
				//显示上传按钮
				videoForm: {
					showVideoPath: ''
				}, 
				video_s: '',  
				//----------------



				messageToHelloWorld: '我是父组件向下传递的信息',
				id: 0,
				dialogVisible: false,
				gifts: [],
				index: 0,
				checkedGift: {
					id: 60,
					uid: 60,
					name: '玫瑰花',
					url: require('../assets/gifts/5.jpg'),
					bid: 0,
					num: 0,
					message: '',

				},
				finfo: {},
				num: 1,
				title: '',
				textarea: '',
				simple:'',
				price:'',
			};
		},
		created() {
			

		},

		watch: {
			info: function(newVal, oldVal) {
				this.finfo = newVal; //newVal即是chartData
				this.drawChart();
			}
		},
		mounted() { 

		},
		methods: {
			 
			 

			 
			open(e) { 
				this.id = e
				this.dialogVisible = true
				
				const params = new URLSearchParams();
				params.append('id', e); 
				
				axios.post(this.apiUrl + '/api/user/getUpdateDatasInfo', params)
					.then((response) => {
				
						this.info = response.data.data;
				
					})
					.catch(function(error) {
						console.log(error);
					});
					
			
			},
			 
		}
	}
</script>

<style scoped lang="scss">
   
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.video-avatar {
		width: 400px;
		height: 200px;
	}



	.gifts {
		.title {
			margin-bottom: 21px;
			color: #333;
		}

		.gift {
			width: 84px;
			height: 84px;
		}

		.el-icon-success {
			position: absolute;
			bottom: 5px;
			right: 50%;
			transform: translateX(42px);
			font-size: 17px;
			color: #FF2D2D;
		}

		.el-pagination {
			text-align: center;
		}
	}

	.info {
		&>div {
			margin-bottom: 10px;
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 18px 16px;
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button--primary {
			width: 95px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-pager li:last-child {
		border-color: $green
	}

	.el-col-4 {
		text-align: center;
		position: relative;
		margin-bottom: 16px;
	}

	.el-input-number--mini {
		width: 74px;
		line-height: 22px;
	}

	/*/deep/ .el-input__inner{*/
	/*  border: unset;*/
	/*}*/
	.el-input-number__decrease,
	.el-input-number__increase {
		background-color: transparent;
	}

	/deep/ .el-input--mini .el-input__inner {
		padding-left: 0;
		padding-right: 0;
	}

	/deep/ .el-input--mini .el-input__inner {
		height: 24px;
	}

	/deep/ .el-input-number--mini .el-input-number__decrease,
	/deep/ .el-input-number--mini .el-input-number__increase {
		width: 20px;
	}
</style>
