<template>
	<el-dialog title="资料详情" :visible.sync="dialogVisible" width="612px">
	 
		<div class="info" v-if="is_pay">

			<div style=" text-align: center;"> 
				<p> {{data.title}}</p>
			</div>
			
			<div style=" text-align: center;"> 
				<p> {{data.content}}</p>
			</div>


			<!-- <div> 上传视频：  
			</div> -->



		</div>
		<div class="info" v-if="!is_pay">
		
			<div style=" text-align: center;"> 
				<p> {{data.title}}</p>
			</div>
			
			<div style=" text-align: center;"> 
				<p> {{data.simple}}</p>
			</div>
			
		 请扫码支付查看完整内容
		<div style="height: 200px;">
			
			<div style="width: 50%; float: left; text-align:center">
				<p> &nbsp;</p>
				<br/>
				<p> 微信：</p>
				
			  <img :src="pay_img" >
			</div>
		
		
				
			<div style="width: 50%; float: left; text-align:center "> 
			
			
				<p>     &nbsp;</p>
				<br/> 
				<p>支付宝：</p>
			  <img :src="pay_img" >
			</div>
				</div>
		
		</div>
		
		
		<span slot="footer" class="dialog-footer" v-if="!is_pay" >
			<el-button type="primary" @click="">已支付</el-button>
		</span>
	</el-dialog>


</template>

<script>
	import axios from "axios"; 
	export default {
		name: "MyUpdata",
		  
		data() {
			return { 
				pay_img:'',
				is_pay:0,
				id:1,
				 data:[],
				 dialogVisible:false,
			};
		},
		created() {
			

		},
  
		methods: {

			 
			open(e) {  
				this.id = e
				this.dialogVisible = true 
				
				
				
				const params1 = new URLSearchParams();
				params1.append('u_id', sessionStorage.getItem('uid')); 
				params1.append('type_id', this.id); 
				//判断支付状态   
				axios.post(this.apiUrl + '/api/user/getIsStudyPay', params1)
				.then((response) => {
								
					this.is_pay = response.data.code;
					// 0:未支付  1已支付
				 
					
					if(this.is_pay == 0){
						//展示 支付码
						
						const params2 = new URLSearchParams();
						//params1.append('id', sessionStorage.getItem('uid')); 
						params2.append('id', this.id); 
						
						axios.post(this.apiUrl + '/api/user/getUpdateInfors', params2)
							.then((response) => {
						
								this.data = response.data.data;
						
							})
							.catch(function(error) {
								console.log(error);
							});
						
						
						
						const params3 = new URLSearchParams();
						params3.append('uid', sessionStorage.getItem('uid')); 
						params3.append('id', this.id); 
						
						axios.post(this.apiUrl + '/api/user/getStudyPayImg', params3)
							.then((response) => {
						
								this.pay_img = response.data;
						
							})
							.catch(function(error) {
								console.log(error);
							});
							
							
							
							
					}
					
					if(this.is_pay == 1){
						//展示全部 内容
						
						const params2 = new URLSearchParams();
						//params1.append('id', sessionStorage.getItem('uid')); 
						params2.append('id', this.id); 
						
						axios.post(this.apiUrl + '/api/user/getUpdateInfors', params2)
							.then((response) => {
						
								this.data = response.data.data;
						
							})
							.catch(function(error) {
								console.log(error);
							});
							
							
						
					}
								
				})
				.catch(function(error) {
					console.log(error);
				});	
					
				
				//未支付 
				
				
				//已支付
				
				
				
				
				// const params1 = new URLSearchParams();
				// //params1.append('id', sessionStorage.getItem('uid')); 
				// params1.append('id', this.id); 
				
				// axios.post(this.apiUrl + '/api/user/getUpdateInfors', params1)
				// 	.then((response) => {
				
				// 		this.data = response.data.data;
				
				// 	})
				// 	.catch(function(error) {
				// 		console.log(error);
				// 	});
					
					
			} 
			
			
		}
	}
</script>

<style scoped lang="scss">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.video-avatar {
		width: 400px;
		height: 200px;
	}



	.gifts {
		.title {
			margin-bottom: 21px;
			color: #333;
		}

		.gift {
			width: 84px;
			height: 84px;
		}

		.el-icon-success {
			position: absolute;
			bottom: 5px;
			right: 50%;
			transform: translateX(42px);
			font-size: 17px;
			color: #FF2D2D;
		}

		.el-pagination {
			text-align: center;
		}
	}

	.info {
		&>div {
			margin-bottom: 10px;
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 18px 16px;
	}

	/deep/ .el-dialog__footer {
		text-align: center;

		.el-button--primary {
			width: 95px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-pager li:last-child {
		border-color: $green
	}

	.el-col-4 {
		text-align: center;
		position: relative;
		margin-bottom: 16px;
	}

	.el-input-number--mini {
		width: 74px;
		line-height: 22px;
	}

	/*/deep/ .el-input__inner{*/
	/*  border: unset;*/
	/*}*/
	.el-input-number__decrease,
	.el-input-number__increase {
		background-color: transparent;
	}

	/deep/ .el-input--mini .el-input__inner {
		padding-left: 0;
		padding-right: 0;
	}

	/deep/ .el-input--mini .el-input__inner {
		height: 24px;
	}

	/deep/ .el-input-number--mini .el-input-number__decrease,
	/deep/ .el-input-number--mini .el-input-number__increase {
		width: 20px;
	}
</style>
