<template>
	<el-dialog :title="title" :visible.sync="dialogVisible" width="612px">
 
		<!--病人确认费用-->
		<div class="main" >
			<p class="title">支付费用</p>
			<p class="price">￥ <span>{{total}}</span></p>  


			<div class="gifts clearFix" style="text-align:center">
				<div style="float: left; width:50%">
					<img :src="this.wx">
					<p>微信</p>
				</div>

				<div style="float: left; width:50%">
					<img :src="this.zfb">
					<p>支付宝</p>
				</div>
			</div>

 


		</div>
		 
		<!--底部操作按钮-->
		<span slot="footer" class="dialog-footer"> 
			<el-button type="primary" @click="goToPay"  >支付成功</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import axios from "axios";
	export default {
		name: 'PayStatus',
		components: {},
		props: ['message1', 'pay_data'],
		data() {
			return {
				wx: '',
				zfb: '',
				gifts: 11,
				dialogVisible: false,
				title: '去支付',
				price: '',
				coupons: [ 
				],
				currentIndex: 0,
				total: 1,
				currentWay: 'wx'
			}
		},
		computed: {
			couponNum() {
				return this.coupons[this.currentIndex].num
			}
		},
		created() {},
		methods: {
			open(id,price) {
				//alert(id)
				this.handleSend3333(id)
				this.title = '去支付'
				this.dialogVisible = true
				this.total = price
			},

			handleSend3333(id) {

				const params = new URLSearchParams();
				params.append('price', 0);
				params.append('uid', sessionStorage.getItem('uid'));
				params.append('did', 0);
				params.append('type', 3);
				params.append('id', id); 

				axios.post(this.apiUrl + '/api/user/getWxPayImg', params)
					.then((response) => {
						this.$emit('sendPayImg', response.data)
						var data = response.data; 
						var a = eval(data);
						var price = a.price;
						this.wx = a.wx;
						this.zfb = a.zfb;  

					})
					.catch(function(error) {
						console.log(error);
					});



			},


			//支付排名
			handleSend44(e) {
				const params3 = new URLSearchParams(); 
				params3.append('uid', sessionStorage.getItem('uid'));
				axios.post(this.apiUrl + '/api/user/setPayRank', params3)
					.then((response) => {
						this.$message.success('操作成功！');
						window.location.reload(); 
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//支付礼物
			handleSend33(e) {

				const params3 = new URLSearchParams();

				params3.append('uid', sessionStorage.getItem('uid'));

				params3.append('bid', e.bid);

				params3.append('name', e.name);

				params3.append('url', e.url);

				params3.append('num', e.num);

				params3.append('message', e.message);

				axios.post(this.apiUrl + '/api/user/setPayGift', params3)
					.then((response) => {
						this.$message.success('操作成功！');
						window.location.reload();

					})
					.catch(function(error) {
						console.log(error);
					});
			},

			handleSend() {
				const params = new URLSearchParams();
				axios.post(this.apiUrl + '/api/user/setPayGift', this.gifts)
					.then((response) => {
						console.log(response.data.data);
						this.info = response.data.data;

					})
					.catch(function(error) {
						console.log(error);
					});
			},
			goToPay() {
				// this.$refs.handleSend2();
				//this.handleSend2();
				// alert(message1.name)
				this.dialogVisible = false
			},
			handleWay(e) {
				console.log(e);
			}
		}
	}
</script>

<style scoped lang="scss">
	.main {
		.title {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 20px;
			padding-bottom: 14px;
		}

		.el-input {
			margin-bottom: 90px;
		}

		.price {
			font-size: 18px;
			color: #333;
			padding-left: 20px;

			span {
				font-size: 40px;
			}
		}

		.tip {
			font-size: 12px;
			color: #FF1515;
			line-height: 17px;
			padding: 18px 0 59px;
		}

		.pay_text {
			color: #999;
			font-size: 18px;
			margin-bottom: 30px;

			&>span {
				margin-right: 48px;
			}

			.red {
				color: #FF2828;

				span {
					font-size: 40px;
				}
			}
		}

		.coupon_wrap {
			margin-bottom: 17px;
			width: 100%;
			height: 80px;

			.coupons {
				display: flex;
				height: 56px;

				.coupon {
					flex-shrink: 0;
					width: 150px;
					height: 56px;
					margin-right: 24px;
					background-color: $green;
					font-size: 12px;
					color: #fff;
					text-align: center;
					line-height: 18px;
					padding-top: 4px;

					.coupon_num {
						font-size: 14px;
						padding-right: 10px;
					}

					.red {
						background-color: #FF2828;
						display: inline-block;
						position: relative;
						bottom: -2px;
						padding: 0 4px;
					}
				}

				.choosed {
					background-color: #A5E3E7;
				}
			}

		}

		.payWays {
			margin-bottom: 90px;
			display: flex;

			.wayItem {
				height: 55px;
				margin-right: 40px;
				position: relative;

				i {
					position: absolute;
					bottom: 10px;
					right: 0;
					font-size: 21px;
					color: #FF2828;
				}
			}
		}
	}

	/deep/ .el-dialog__header {
		background-color: $green;
		height: 33px;
		padding: 0 0 0 27px;

		.el-dialog__title {
			line-height: 33px;
			font-size: 12px;
			color: #fff;
		}

		.el-dialog__headerbtn {
			top: 8px;

			.el-dialog__close {
				color: #fff;
			}
		}
	}

	/deep/ .el-dialog__body {
		padding: 14px 20px;

		.el-input__prefix {
			left: 20px !important;
			line-height: 48px;
			font-size: 12px;
			color: #333;
		}

		.el-input--medium .el-input__inner {
			text-indent: 18px;
			height: 48px;
		}
	}

	/deep/ .el-dialog__footer {
		text-align: center;
		padding-bottom: 50px;

		.el-button--primary {
			width: 190px;
			height: 32px;
			background-color: $green;
			border-color: $green;
		}
	}

	/deep/ .el-scrollbar__wrap {
		overflow-y: hidden !important;
	}
</style>
